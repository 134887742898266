import {
  ESI_PROJECT_API_RESPONSE_FIELD_KEY,
  ESI_PROJECT_FIELD_KEY
} from "./chunk-ML47FAX6.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  Utils_default
} from "./chunk-TLJMJLS5.js";
import {
  BaseMasterDataItemModel_default
} from "./chunk-ANKRQHQU.js";
import {
  BaseMasterDataModel_default
} from "./chunk-MQEE5OUD.js";

// src/Project/ProjectModel.ts
var ProjectModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new ProjectItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var ProjectFilterModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.getPagination = () => {
      const pagination = this.pureEntities.result.pagination;
      return {
        page: pagination.page ? parseInt(pagination.page) : 1,
        pageLimit: pagination.pageLimit ? parseInt(pagination.pageLimit) : 0,
        limitstart: pagination.limitStart ? parseInt(pagination.limitStart) : 0,
        totalItems: pagination.totalItems ? parseInt(pagination.totalItems) : 0,
        totalPages: pagination.totalPage ? parseInt(pagination.totalPage) : 0
      };
    };
    if (entities) {
      this.unTransformedItems = entities.result.data;
      this.items = entities.result.data.map((element) => {
        return new ProjectItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var ProjectItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.startDate = "0000-00-00 00:00:00";
    this.endDate = "0000-00-01 00:00:00";
    this.shortDescription = "";
    this.logo = "";
    this.projectLead = null;
    this.status = 0;
    this.getStartDate = () => {
      return this.startDate;
    };
    this.getEndDate = () => {
      return this.endDate;
    };
    this.getShortDescription = () => {
      return this.shortDescription;
    };
    this.getLogo = () => {
      return this.logo;
    };
    this.getProjectLead = () => {
      return this.projectLead;
    };
    this.getStatus = () => {
      return this.status;
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [ESI_PROJECT_FIELD_KEY.ID]: this.id,
        [ESI_PROJECT_FIELD_KEY.TITLE]: this.title,
        [ESI_PROJECT_FIELD_KEY.START_DATE]: Utils_default.formatDatetimeByLocale(this.startDate),
        [ESI_PROJECT_FIELD_KEY.END_DATE]: Utils_default.formatDatetimeByLocale(this.endDate),
        [ESI_PROJECT_FIELD_KEY.SHORT_DESCRIPTION]: this.shortDescription,
        [ESI_PROJECT_FIELD_KEY.LOGO]: this.logo,
        [ESI_PROJECT_FIELD_KEY.PROJECT_LEAD]: this.projectLead,
        [ESI_PROJECT_FIELD_KEY.STATUS]: this.status
      };
    };
    if (entity) {
      this.startDate = entity[ESI_PROJECT_API_RESPONSE_FIELD_KEY.START_DATE] ?? "0000-00-00 00:00:00";
      this.endDate = entity[ESI_PROJECT_API_RESPONSE_FIELD_KEY.END_DATE] ?? "0000-00-00 00:00:00";
      this.shortDescription = entity[ESI_PROJECT_API_RESPONSE_FIELD_KEY.SHORT_DESCRIPTION] ?? "";
      this.logo = entity[ESI_PROJECT_API_RESPONSE_FIELD_KEY.LOGO] ?? "";
      this.projectLead = entity[ESI_PROJECT_API_RESPONSE_FIELD_KEY.PROJECT_LEAD] ?? 0;
      this.status = entity[ESI_PROJECT_API_RESPONSE_FIELD_KEY.STATUS] ?? 0;
    }
  }
};
ProjectItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.TITLE]: data[ESI_PROJECT_FIELD_KEY.TITLE] ?? "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.START_DATE]: data[ESI_PROJECT_FIELD_KEY.START_DATE] ?? "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.END_DATE]: data[ESI_PROJECT_FIELD_KEY.END_DATE] ?? "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.SHORT_DESCRIPTION]: data[ESI_PROJECT_FIELD_KEY.SHORT_DESCRIPTION] ?? "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.LOGO]: data[ESI_PROJECT_FIELD_KEY.LOGO] ?? "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.PROJECT_LEAD]: data[ESI_PROJECT_FIELD_KEY.PROJECT_LEAD] ?? "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.STATUS]: data[ESI_PROJECT_FIELD_KEY.STATUS] ?? ""
  };
};
ProjectItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.ID]: data[ESI_PROJECT_FIELD_KEY.ID],
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.TITLE]: data[ESI_PROJECT_FIELD_KEY.TITLE],
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.START_DATE]: data[ESI_PROJECT_FIELD_KEY.START_DATE] ? new Date(data[ESI_PROJECT_FIELD_KEY.START_DATE]) : "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.END_DATE]: data[ESI_PROJECT_FIELD_KEY.END_DATE] ? new Date(data[ESI_PROJECT_FIELD_KEY.END_DATE]) : "",
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.SHORT_DESCRIPTION]: data[ESI_PROJECT_FIELD_KEY.SHORT_DESCRIPTION],
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.LOGO]: data[ESI_PROJECT_FIELD_KEY.LOGO],
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.PROJECT_LEAD]: data[ESI_PROJECT_FIELD_KEY.PROJECT_LEAD],
    [ESI_PROJECT_API_RESPONSE_FIELD_KEY.STATUS]: data[ESI_PROJECT_FIELD_KEY.STATUS]
  };
};
var ProjectMasterDataItemModel = class extends BaseMasterDataItemModel_default {
  constructor() {
    super(...arguments);
    this.toJSON = () => {
      return {
        [ESI_PROJECT_FIELD_KEY.ID]: this.id,
        [ESI_PROJECT_FIELD_KEY.TITLE]: this.title
      };
    };
  }
};
var ProjectMasterDataModel = class extends BaseMasterDataModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities.result;
      this.items = entities.result.map((element) => {
        return new ProjectMasterDataItemModel(element);
      });
    }
  }
};

export {
  ProjectModel,
  ProjectFilterModel,
  ProjectItemModel,
  ProjectMasterDataItemModel,
  ProjectMasterDataModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

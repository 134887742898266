import {
  InstanceBillingPlan_default
} from "./chunk-OEI67XZ4.js";
import {
  Instance_default
} from "./chunk-K7Z5Y6B4.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/BillingPlan/BillingPlanRoute.ts
var BillingPlanRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.createSubscriptionRequest = (planName) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "payment_paddle",
          task: "getPayLink",
          planName
        })
      );
    };
    this.getMemberInvoices = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "payment_paddle",
          task: "getMemberInvoices"
        })
      );
    };
    this.getMemberSubscriptionDetail = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "payment_paddle",
          task: "getMemberSubscriptionDetail"
        })
      );
    };
    this.changeSubscriptionPlan = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "payment_paddle",
          task: "changeSubscriptionPlan"
        }),
        data
      );
    };
    this.cancelSubscription = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "payment_paddle",
          task: "cancelSubscription"
        })
      );
    };
    this.getFeatureMemberRequest = (memberId) => {
      return InstanceBillingPlan_default.get("/api/features-member/" + memberId);
    };
    this.getHistoryUploadQuotasRequest = (organizationId) => {
      return InstanceBillingPlan_default.get("/api/upload-history-quotas/" + organizationId);
    };
  }
};
var BillingPlanRoute_default = BillingPlanRoute;

export {
  BillingPlanRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  PERSONA_FIELD_KEY,
  PERSONA_RESPONSE_FIELD_KEY
} from "./chunk-O4JXOBLV.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Persona/PersonaModel.ts
var PersonaModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new PersonaItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var PersonaFilterModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.getPagination = () => {
      const pagination = this.pureEntities.result.pagination;
      return {
        page: pagination.page ? parseInt(pagination.page) : 1,
        pageLimit: pagination.pageLimit ? parseInt(pagination.pageLimit) : 0,
        limitstart: pagination.limitStart ? parseInt(pagination.limitStart) : 0,
        totalItems: pagination.totalItems ? parseInt(pagination.totalItems) : 0,
        totalPages: pagination.totalPage ? parseInt(pagination.totalPage) : 0
      };
    };
    if (entities) {
      this.unTransformedItems = entities.result.data;
      this.items = entities.result.data.map((element) => {
        return new PersonaItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var PersonaItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.name = "";
    this.avatar = "";
    this.avatar_2 = "";
    this.location = "";
    this.age_from = "";
    this.age_to = "";
    this.gender = "";
    this.channel = 0;
    this.demographics_type = "";
    this.demographics_select = "";
    this.interests_type = "";
    this.interests_select = "";
    this.behaviors_type = "";
    this.behaviors_select = "";
    this.location_all_contries = "";
    this.toObject = () => {
      return {
        [PERSONA_FIELD_KEY.ID]: this.id,
        [PERSONA_FIELD_KEY.NAME]: this.name,
        [PERSONA_FIELD_KEY.AVATAR]: this.avatar,
        [PERSONA_FIELD_KEY.AVATAR_2]: this.avatar_2,
        [PERSONA_FIELD_KEY.LOCATION]: this.location,
        [PERSONA_FIELD_KEY.AGE_FROM]: this.age_from,
        [PERSONA_FIELD_KEY.AGE_TO]: this.age_to,
        [PERSONA_FIELD_KEY.GENDER]: this.gender,
        [PERSONA_FIELD_KEY.CHANNEL]: this.channel,
        [PERSONA_FIELD_KEY.DEMOGRAPHICS_TYPE]: this.demographics_type,
        [PERSONA_FIELD_KEY.DEMOGRAPHICS_SELECT]: this.demographics_select,
        [PERSONA_FIELD_KEY.INTERESTS_TYPE]: this.interests_type,
        [PERSONA_FIELD_KEY.INTERESTS_SELECT]: this.interests_select,
        [PERSONA_FIELD_KEY.BEHAVIORS_TYPE]: this.behaviors_type,
        [PERSONA_FIELD_KEY.BEHAVIORS_SELECT]: this.behaviors_select,
        [PERSONA_FIELD_KEY.ALL_COUNTRIES]: this.location_all_contries
      };
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PERSONA_FIELD_KEY.ID]: this.id,
        [PERSONA_FIELD_KEY.NAME]: this.name,
        [PERSONA_FIELD_KEY.AVATAR]: this.avatar,
        [PERSONA_FIELD_KEY.AVATAR_2]: this.avatar_2,
        [PERSONA_FIELD_KEY.LOCATION]: this.location,
        [PERSONA_FIELD_KEY.AGE_FROM]: this.age_from,
        [PERSONA_FIELD_KEY.AGE_TO]: this.age_to,
        [PERSONA_FIELD_KEY.GENDER]: this.gender,
        [PERSONA_FIELD_KEY.CHANNEL]: this.channel,
        [PERSONA_FIELD_KEY.DEMOGRAPHICS_TYPE]: this.demographics_type,
        [PERSONA_FIELD_KEY.DEMOGRAPHICS_SELECT]: this.demographics_select,
        [PERSONA_FIELD_KEY.INTERESTS_TYPE]: this.interests_type,
        [PERSONA_FIELD_KEY.INTERESTS_SELECT]: this.interests_select,
        [PERSONA_FIELD_KEY.BEHAVIORS_TYPE]: this.behaviors_type,
        [PERSONA_FIELD_KEY.BEHAVIORS_SELECT]: this.behaviors_select,
        [PERSONA_FIELD_KEY.ALL_COUNTRIES]: this.location_all_contries
      };
    };
    if (entity) {
      this.name = entity[PERSONA_RESPONSE_FIELD_KEY.NAME] ?? "";
      this.avatar = entity[PERSONA_RESPONSE_FIELD_KEY.AVATAR] ?? "";
      this.avatar_2 = entity[PERSONA_RESPONSE_FIELD_KEY.AVATAR_2] ?? "";
      this.location = entity[PERSONA_RESPONSE_FIELD_KEY.LOCATION] ?? "";
      this.age_from = entity[PERSONA_RESPONSE_FIELD_KEY.AGE_FROM] ?? "";
      this.age_to = entity[PERSONA_RESPONSE_FIELD_KEY.AGE_TO] ?? "";
      this.gender = entity[PERSONA_RESPONSE_FIELD_KEY.GENDER] ?? "";
      this.channel = entity[PERSONA_RESPONSE_FIELD_KEY.CHANNEL] ?? "";
      this.demographics_type = entity[PERSONA_RESPONSE_FIELD_KEY.DEMOGRAPHICS_TYPE] ?? "";
      this.demographics_select = entity[PERSONA_RESPONSE_FIELD_KEY.DEMOGRAPHICS_SELECT] ?? "";
      this.interests_type = entity[PERSONA_RESPONSE_FIELD_KEY.INTERESTS_TYPE] ?? "";
      this.interests_select = entity[PERSONA_RESPONSE_FIELD_KEY.INTERESTS_SELECT] ?? "";
      this.behaviors_type = entity[PERSONA_RESPONSE_FIELD_KEY.BEHAVIORS_TYPE] ?? "";
      this.behaviors_select = entity[PERSONA_RESPONSE_FIELD_KEY.BEHAVIORS_SELECT] ?? "";
      this.location_all_contries = entity[PERSONA_RESPONSE_FIELD_KEY.ALL_COUNTRIES] ?? "";
    }
  }
};
PersonaItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [PERSONA_RESPONSE_FIELD_KEY.NAME]: data[PERSONA_FIELD_KEY.NAME] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AVATAR]: data[PERSONA_FIELD_KEY.AVATAR] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AVATAR_2]: data[PERSONA_FIELD_KEY.AVATAR_2] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.LOCATION]: data[PERSONA_FIELD_KEY.LOCATION] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AGE_FROM]: data[PERSONA_FIELD_KEY.AGE_FROM] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AGE_TO]: data[PERSONA_FIELD_KEY.AGE_TO] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.GENDER]: data[PERSONA_FIELD_KEY.GENDER] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.CHANNEL]: data[PERSONA_FIELD_KEY.CHANNEL] ? data[PERSONA_FIELD_KEY.CHANNEL] : "",
    [PERSONA_RESPONSE_FIELD_KEY.DEMOGRAPHICS_TYPE]: data[PERSONA_FIELD_KEY.DEMOGRAPHICS_TYPE] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.DEMOGRAPHICS_SELECT]: data[PERSONA_FIELD_KEY.DEMOGRAPHICS_SELECT] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.INTERESTS_TYPE]: data[PERSONA_FIELD_KEY.INTERESTS_TYPE] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.INTERESTS_SELECT]: data[PERSONA_FIELD_KEY.INTERESTS_SELECT] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.BEHAVIORS_TYPE]: data[PERSONA_FIELD_KEY.BEHAVIORS_TYPE] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.BEHAVIORS_SELECT]: data[PERSONA_FIELD_KEY.BEHAVIORS_SELECT] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.ALL_COUNTRIES]: data[PERSONA_FIELD_KEY.ALL_COUNTRIES] ?? ""
  };
};
PersonaItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [PERSONA_RESPONSE_FIELD_KEY.ID]: data[PERSONA_FIELD_KEY.ID] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.NAME]: data[PERSONA_FIELD_KEY.NAME] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AVATAR]: data[PERSONA_FIELD_KEY.AVATAR] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AVATAR_2]: data[PERSONA_FIELD_KEY.AVATAR_2] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.LOCATION]: data[PERSONA_FIELD_KEY.LOCATION] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AGE_FROM]: data[PERSONA_FIELD_KEY.AGE_FROM] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.AGE_TO]: data[PERSONA_FIELD_KEY.AGE_TO] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.GENDER]: data[PERSONA_FIELD_KEY.GENDER] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.CHANNEL]: data[PERSONA_FIELD_KEY.CHANNEL] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.DEMOGRAPHICS_TYPE]: data[PERSONA_FIELD_KEY.DEMOGRAPHICS_TYPE] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.DEMOGRAPHICS_SELECT]: data[PERSONA_FIELD_KEY.DEMOGRAPHICS_SELECT] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.INTERESTS_TYPE]: data[PERSONA_FIELD_KEY.INTERESTS_TYPE] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.INTERESTS_SELECT]: data[PERSONA_FIELD_KEY.INTERESTS_SELECT] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.BEHAVIORS_TYPE]: data[PERSONA_FIELD_KEY.BEHAVIORS_TYPE] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.BEHAVIORS_SELECT]: data[PERSONA_FIELD_KEY.BEHAVIORS_SELECT] ?? "",
    [PERSONA_RESPONSE_FIELD_KEY.ALL_COUNTRIES]: data[PERSONA_FIELD_KEY.ALL_COUNTRIES] ?? ""
  };
};

export {
  PersonaModel,
  PersonaFilterModel,
  PersonaItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  MEMBER_ROLE_FIELD
} from "./chunk-R43B7PK2.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Organization/MemberRole/Model.ts
var MemberRoleModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded?.item.map((element) => {
        return new MemberRoleItemModel(element);
      });
    }
  }
};
var MemberRoleItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = 0;
    this.member_id = null;
    this.member_name = null;
    this.role_id = null;
    this.role_name = null;
    this.organisation_id = null;
    this.name = null;
    this.state = null;
    this.published = null;
    this.created_user_name = null;
    this.modified_user_name = null;
    this.created_time = null;
    this.modified_time = null;
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [MEMBER_ROLE_FIELD.ID]: this.id,
        [MEMBER_ROLE_FIELD.MEMBER_ID]: this.member_id,
        [MEMBER_ROLE_FIELD.MEMBER_NAME]: this.member_name,
        [MEMBER_ROLE_FIELD.ROLE_ID]: this.role_id,
        [MEMBER_ROLE_FIELD.ROLE_NAME]: this.role_name,
        [MEMBER_ROLE_FIELD.NAME]: this.name,
        [MEMBER_ROLE_FIELD.STATE]: this.state,
        [MEMBER_ROLE_FIELD.PUBLISHED]: this.published,
        [MEMBER_ROLE_FIELD.ORGANISATION_ID]: this.organisation_id,
        [MEMBER_ROLE_FIELD.CREATED_USER_NAME]: this.created_user_name,
        [MEMBER_ROLE_FIELD.MODIFIED_USER_NAME]: this.modified_user_name,
        [MEMBER_ROLE_FIELD.CREATED_TIME]: this.created_time,
        [MEMBER_ROLE_FIELD.MODIFIED_TIME]: this.modified_time,
        [MEMBER_ROLE_FIELD.NAME]: this.name
      };
    };
    this.toObject = () => {
      return {};
    };
    if (entity) {
      this.id = entity[MEMBER_ROLE_FIELD.ID] ?? 0;
      this.member_id = entity[MEMBER_ROLE_FIELD.MEMBER_ID] ?? 0;
      this.member_name = entity[MEMBER_ROLE_FIELD.MEMBER_NAME] ?? "";
      this.role_id = entity[MEMBER_ROLE_FIELD.ROLE_ID] ?? 0;
      this.role_name = entity[MEMBER_ROLE_FIELD.ROLE_NAME] ?? "";
      this.name = entity[MEMBER_ROLE_FIELD.NAME] ?? "";
      this.state = entity[MEMBER_ROLE_FIELD.STATE] ?? "";
      this.published = entity[MEMBER_ROLE_FIELD.PUBLISHED] ?? 0;
      this.organisation_id = entity[MEMBER_ROLE_FIELD.ORGANISATION_ID] ?? 0;
      this.created_user_name = entity[MEMBER_ROLE_FIELD.CREATED_USER_NAME] ?? "";
      this.modified_user_name = entity[MEMBER_ROLE_FIELD.MODIFIED_USER_NAME] ?? "";
      this.created_time = entity[MEMBER_ROLE_FIELD.CREATED_TIME] ?? "";
      this.modified_time = entity[MEMBER_ROLE_FIELD.MODIFIED_TIME] ?? "";
    }
  }
};
MemberRoleItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [MEMBER_ROLE_FIELD.ID];
  Object.keys(MEMBER_ROLE_FIELD).forEach((index) => {
    if (!excluded.includes(MEMBER_ROLE_FIELD[index]) && data[MEMBER_ROLE_FIELD[index]]) {
      formData.append(MEMBER_ROLE_FIELD[index], data[MEMBER_ROLE_FIELD[index]]);
    }
  });
  return formData;
};
MemberRoleItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [MEMBER_ROLE_FIELD.CUSTOM_FIELDS];
  Object.keys(MEMBER_ROLE_FIELD).forEach((index) => {
    if (!excluded.includes(MEMBER_ROLE_FIELD[index]) && data[MEMBER_ROLE_FIELD[index]]) {
      formData[MEMBER_ROLE_FIELD[index]] = data[MEMBER_ROLE_FIELD[index]];
    }
  });
  return formData;
};

export {
  MemberRoleModel,
  MemberRoleItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

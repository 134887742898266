import {
  CMS_CATE_DETAIL_FIELD_KEY
} from "./chunk-GXKHLBEY.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Cms/FieldsGroup/CMSFieldsGroupModel.ts
var FieldsGroupModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new FieldsGroupItemModel(element);
      });
    }
  }
};
var FieldsGroupItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.check = false;
    this.engagement = "";
    this.languages = "";
    this.name = "";
    this.status = "";
    this.type = "";
    this.visits = "";
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CMS_CATE_DETAIL_FIELD_KEY.ID]: this.id,
        [CMS_CATE_DETAIL_FIELD_KEY.CHECK]: this.check,
        [CMS_CATE_DETAIL_FIELD_KEY.ENGAGEMENT]: this.engagement,
        [CMS_CATE_DETAIL_FIELD_KEY.LANGUAGES]: this.languages,
        [CMS_CATE_DETAIL_FIELD_KEY.NAME]: this.name,
        [CMS_CATE_DETAIL_FIELD_KEY.STATUS]: this.status,
        [CMS_CATE_DETAIL_FIELD_KEY.TYPE]: this.type,
        [CMS_CATE_DETAIL_FIELD_KEY.VISITS]: this.visits
      };
    };
    if (entity) {
      this.id = entity[CMS_CATE_DETAIL_FIELD_KEY.ID] ?? "";
      this.check = entity[CMS_CATE_DETAIL_FIELD_KEY.CHECK] ?? "";
      this.engagement = entity[CMS_CATE_DETAIL_FIELD_KEY.ENGAGEMENT] ?? "";
      this.languages = entity[CMS_CATE_DETAIL_FIELD_KEY.LANGUAGES] ?? "";
      this.name = entity[CMS_CATE_DETAIL_FIELD_KEY.NAME] ?? "";
      this.status = entity[CMS_CATE_DETAIL_FIELD_KEY.STATUS] ?? "";
      this.type = entity[CMS_CATE_DETAIL_FIELD_KEY.TYPE] ?? "";
      this.visits = entity[CMS_CATE_DETAIL_FIELD_KEY.VISITS] ?? "";
    }
  }
  // static __transformItemToApiOfUpdation = (data) => {
  //   let formData = {};
  //   const excluded = [
  //     CMS_CATE_DETAIL_FIELD_KEY.CUSTOM_FIELDS,
  //     CMS_CATE_DETAIL_FIELD_KEY.TAGS,
  //   ];
  //   Object.keys(CMS_CATE_DETAIL_FIELD_KEY).forEach((index) => {
  //     if (
  //       !excluded.includes(CMS_CATE_DETAIL_FIELD_KEY[index]) &&
  //       data[CMS_CATE_DETAIL_FIELD_KEY[index]]
  //     ) {
  //       formData[CMS_CATE_DETAIL_FIELD_KEY[index]] = data[CMS_CATE_DETAIL_FIELD_KEY[index]];
  //     }
  //   });
  //   if (Object.keys(data[CMS_CATE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
  //     formData['custom_fields'] = Object.keys(data[CMS_CATE_DETAIL_FIELD_KEY.CUSTOM_FIELDS])
  //       .map((key) => {
  //         return {
  //           [key]: data[CMS_CATE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key],
  //         };
  //       })
  //       .reduce((prev, curr) => curr);
  //   }
  //   return formData;
  // };
};
FieldsGroupItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  formData.append(CMS_CATE_DETAIL_FIELD_KEY.ID, data[CMS_CATE_DETAIL_FIELD_KEY.ID] ?? 0);
  return formData;
};

export {
  FieldsGroupModel,
  FieldsGroupItemModel
};

import {
  PLANNING_FIELD_KEY
} from "./chunk-3UAHPXBV.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Planning/PlanningModel.ts
var PlanningModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new PlanningItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var PlanningFilterModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.getPagination = () => {
      const pagination = this.pureEntities.result.pagination;
      return {
        page: pagination.page ? parseInt(pagination.page) : 1,
        pageLimit: pagination.pageLimit ? parseInt(pagination.pageLimit) : 0,
        limitstart: pagination.limitStart ? parseInt(pagination.limitStart) : 0,
        totalItems: pagination.totalItems ? parseInt(pagination.totalItems) : 0,
        totalPages: pagination.totalPage ? parseInt(pagination.totalPage) : 0
      };
    };
    if (entities) {
      this.unTransformedItems = entities.result.data;
      this.items = entities.result.data.map((element) => {
        return new PlanningItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var PlanningItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = 0;
    this.name = "";
    this.startDate = "0000-00-00 00:00:00";
    this.endDate = "0000-00-00 00:00:00";
    this.background = "";
    this.desc = "";
    this.toObject = () => {
      return {
        [PLANNING_FIELD_KEY.ID]: this.id,
        [PLANNING_FIELD_KEY.NAME]: this.name,
        [PLANNING_FIELD_KEY.STARTDATE]: this.startDate,
        [PLANNING_FIELD_KEY.ENDDATE]: this.endDate,
        [PLANNING_FIELD_KEY.BACKGROUND]: this.background,
        [PLANNING_FIELD_KEY.DESC]: this.desc
      };
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PLANNING_FIELD_KEY.ID]: this.id,
        [PLANNING_FIELD_KEY.NAME]: this.name,
        [PLANNING_FIELD_KEY.STARTDATE]: this.startDate,
        [PLANNING_FIELD_KEY.ENDDATE]: this.endDate,
        [PLANNING_FIELD_KEY.BACKGROUND]: this.background,
        [PLANNING_FIELD_KEY.DESC]: this.desc
      };
    };
    if (entity) {
      this.id = entity[PLANNING_FIELD_KEY.ID] ?? this.id;
      this.name = entity[PLANNING_FIELD_KEY.NAME] ?? this.name;
      this.startDate = entity[PLANNING_FIELD_KEY.STARTDATE] ?? this.startDate;
      this.endDate = entity[PLANNING_FIELD_KEY.ENDDATE] ?? this.endDate;
      this.background = entity[PLANNING_FIELD_KEY.BACKGROUND] ?? this.background;
      this.desc = entity[PLANNING_FIELD_KEY.DESC] ?? this.desc;
    }
  }
};

export {
  PlanningModel,
  PlanningFilterModel,
  PlanningItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  Instance_default
} from "./chunk-K7Z5Y6B4.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/ProjectChannel/ProjectChannelRoute.ts
var ProjectChannelRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getProjectChannelItemRequest = (itemId) => Instance_default.get(
      this.createRequestURL({
        option: "projectchannel",
        id: itemId
      })
    );
    this.getProjectChannelsRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "projectchannel",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    /**
     *
     * @param data
     */
    this.createProjectChannelRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "projectchannel"
      }),
      data
    );
    this.postToFanpageRequest = (itemId, content, channelType) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "projectchannel",
          projectId: itemId,
          content,
          channelType,
          task: "post"
        }),
        {
          projectId: itemId,
          content,
          channelType
        }
      );
    };
    /**
     *
     * @param data
     */
    this.updateProjectChannelRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "projectchannel"
      }),
      data
    );
    /**
     *
     * @param projectId
     */
    this.deleteProjectChannelRequest = (itemId) => {
      const ids = itemId.split(",");
      if (ids.length < 2) {
        return Instance_default.delete(
          this.createRequestURL({
            option: "projectpersona",
            id: itemId
          })
        );
      } else {
        return Instance_default.post(
          this.createRequestURL({
            option: "projectpersona",
            task: "deleteAll"
          }),
          {
            id: itemId
          }
        );
      }
    };
    this.loginProjectChannelRequert = (itemId, channelType) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "projectchannel",
          projectId: itemId,
          channelType,
          task: "getLoginUrl"
        }),
        {
          projectId: itemId,
          channelType
        }
      );
    };
    this.checkConnectStatusChannel = (itemId, channelType) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "projectchannel",
          projectId: itemId,
          channelType,
          task: "getCheckConnectedChannel"
        }),
        {
          projectId: itemId,
          channelType
        }
      );
    };
    this.getListFanpageRequest = (itemId) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "projectchannel",
          projectId: itemId,
          task: "getListFanPages"
        }),
        {
          projectId: itemId
        }
      );
    };
    this.connectFanpageRequest = (itemId, pageId) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "projectchannel",
          projectId: itemId,
          pageId,
          task: "selectPageToConnect"
        }),
        {
          projectId: itemId,
          pageId
        }
      );
    };
    this.doLoginCMSRequest = (dataPost) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "projectchannel",
          task: "doLoginCMS",
          ...dataPost
        })
      );
    };
    this.doPostContentToCMSRequest = (dataPost) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "projectchannel",
          task: "post",
          ...dataPost
        })
      );
    };
    this.getProjectChannelsByProjectIdRequest = (projectId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "projectchannel",
          task: "getProjectChannelByProjectId",
          projectId
        })
      );
    };
  }
};
var ProjectChannelRoute_default = ProjectChannelRoute;

export {
  ProjectChannelRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

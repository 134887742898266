import {
  ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY,
  ESI_PROJECT_CHANNEL_FIELD_KEY
} from "./chunk-ZREZ4DQF.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/ProjectChannel/ProjectChannelModel.ts
var ProjectChannelModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = [];
    this.unTransformedItems = [];
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new ProjectChannelItemModel(element);
      });
    }
  }
};
var ProjectChannelByProjectIdModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = [];
    this.unTransformedItems = [];
    if (entities) {
      const projectChannels = entities.result;
      this.unTransformedItems = projectChannels;
      this.items = projectChannels.map((element) => {
        return new ProjectChannelItemModel(element);
      });
    }
  }
};
var ProjectChannelItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.channel = null;
    this.project = null;
    this.handle = "";
    this.channelName = null;
    this.getChannel = () => {
      return this.channel;
    };
    this.getProject = () => {
      return this.project;
    };
    this.getHandle = () => {
      return this.handle;
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [ESI_PROJECT_CHANNEL_FIELD_KEY.ID]: this.id,
        [ESI_PROJECT_CHANNEL_FIELD_KEY.CHANNEL]: this.channel,
        [ESI_PROJECT_CHANNEL_FIELD_KEY.PROJECT]: this.project,
        [ESI_PROJECT_CHANNEL_FIELD_KEY.HANDLE]: this.handle,
        [ESI_PROJECT_CHANNEL_FIELD_KEY.CHANNEL_NAME]: this.channelName
      };
    };
    if (entity) {
      this.channel = entity[ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL] ?? [0];
      this.project = entity[ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.PROJECT] ?? [0];
      this.handle = entity[ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.HANDLE] ?? "";
      this.channelName = entity[ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL_NAME] ?? "";
    }
  }
};
ProjectChannelItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL]: data[ESI_PROJECT_CHANNEL_FIELD_KEY.CHANNEL] ?? "",
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.PROJECT]: data[ESI_PROJECT_CHANNEL_FIELD_KEY.PROJECT] ?? "",
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.HANDLE]: data[ESI_PROJECT_CHANNEL_FIELD_KEY.HANDLE] ?? ""
  };
};
ProjectChannelItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL]: data[ESI_PROJECT_CHANNEL_FIELD_KEY.CHANNEL],
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.PROJECT]: data[ESI_PROJECT_CHANNEL_FIELD_KEY.PROJECT],
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.HANDLE]: data[ESI_PROJECT_CHANNEL_FIELD_KEY.HANDLE]
  };
};
ProjectChannelItemModel.__transformItemToApiOfPostToFB = (postContent) => {
  return {
    [ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY.FBCONTENT]: postContent
  };
};

export {
  ProjectChannelModel,
  ProjectChannelByProjectIdModel,
  ProjectChannelItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  DAM_ASSETS_API_FIELD_KEY,
  DAM_ASSETS_FIELD_KEY,
  DAM_COLLECTION_API_RESPONSE_FIELD_KEY,
  DAM_COLLECTION_FIELD_KEY,
  DAM_SUBSCIPTION_API_FIELD_KEY,
  DAM_SUBSCIPTION_FIELD_KEY
} from "./chunk-HLTVRKC2.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Dam/DamModel.ts
import queryString from "query-string";
var ColectionModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new CollectionItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var CollectionItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.parent_id = null;
    this.name = "";
    this.file_size = 0;
    this.owner = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [DAM_COLLECTION_FIELD_KEY.ID]: this.id,
        [DAM_COLLECTION_FIELD_KEY.PARENT_ID]: this.parent_id,
        [DAM_COLLECTION_FIELD_KEY.NAME]: this.name,
        [DAM_COLLECTION_FIELD_KEY.FILE_SIZE]: this.file_size,
        [DAM_COLLECTION_FIELD_KEY.OWNER]: this.owner
      };
    };
    if (entity) {
      this.id = entity[DAM_COLLECTION_FIELD_KEY.ID] ?? "";
      this.parent_id = entity[DAM_COLLECTION_FIELD_KEY.PARENT_ID] ?? 0;
      this.name = entity[DAM_COLLECTION_FIELD_KEY.NAME] ?? "";
      this.file_size = entity[DAM_COLLECTION_FIELD_KEY.FILE_SIZE] ?? 0;
      this.owner = entity[DAM_COLLECTION_FIELD_KEY.OWNER] ?? "";
    }
  }
};
CollectionItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [DAM_COLLECTION_FIELD_KEY.PARENT_ID];
  Object.keys(DAM_COLLECTION_API_RESPONSE_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(DAM_COLLECTION_FIELD_KEY[index]) && data[DAM_COLLECTION_FIELD_KEY[index]]) {
      formData.append(
        DAM_COLLECTION_API_RESPONSE_FIELD_KEY[index],
        data[DAM_COLLECTION_FIELD_KEY[index]]
      );
    }
  });
  formData.append(
    DAM_COLLECTION_API_RESPONSE_FIELD_KEY.PARENT_ID,
    data[DAM_COLLECTION_FIELD_KEY.PARENT_ID] ?? 0
  );
  return formData;
};
CollectionItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [DAM_COLLECTION_FIELD_KEY.PARENT_ID];
  Object.keys(DAM_COLLECTION_API_RESPONSE_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(DAM_COLLECTION_FIELD_KEY[index]) && data[DAM_COLLECTION_FIELD_KEY[index]]) {
      formData[DAM_COLLECTION_API_RESPONSE_FIELD_KEY[index]] = data[DAM_COLLECTION_FIELD_KEY[index]];
    }
  });
  formData[DAM_COLLECTION_API_RESPONSE_FIELD_KEY.PARENT_ID] = data[DAM_COLLECTION_FIELD_KEY.PARENT_ID] ?? 0;
  return formData;
};
CollectionItemModel.__transformItemToApiOfDelete = (data) => {
  const formData = queryString.stringify(
    { [DAM_COLLECTION_API_RESPONSE_FIELD_KEY.IDS]: data },
    { arrayFormat: "bracket" }
  );
  return formData;
};
CollectionItemModel.__transformItemToApiOfMoveToFolder = (data) => {
  let formData = new FormData();
  if (data[DAM_COLLECTION_FIELD_KEY.PARENT_ID]) {
    formData.append(
      DAM_COLLECTION_FIELD_KEY.PARENT_ID,
      data[DAM_COLLECTION_FIELD_KEY.PARENT_ID] ?? 0
    );
  }
  if (data[DAM_COLLECTION_FIELD_KEY.COLLECTIONIDS]) {
    data[DAM_COLLECTION_FIELD_KEY.COLLECTIONIDS].forEach((collection) => {
      formData.append([DAM_COLLECTION_API_RESPONSE_FIELD_KEY.COLLECTIONIDS] + "[]", collection);
    });
  }
  if (data[DAM_COLLECTION_FIELD_KEY.ASSETSIDS]) {
    data[DAM_COLLECTION_FIELD_KEY.ASSETSIDS].forEach((asset) => {
      formData.append([DAM_COLLECTION_API_RESPONSE_FIELD_KEY.ASSETSIDS] + "[]", asset);
    });
  }
  return formData;
};
CollectionItemModel.__transformItemToApiOfDownload = (data) => {
  let formData = new FormData();
  if (data[DAM_COLLECTION_FIELD_KEY.COLLECTIONIDS]) {
    data[DAM_COLLECTION_FIELD_KEY.COLLECTIONIDS].forEach((collection) => {
      formData.append([DAM_COLLECTION_API_RESPONSE_FIELD_KEY.COLLECTIONIDS] + "[]", collection);
    });
  }
  if (data[DAM_COLLECTION_FIELD_KEY.ASSETSIDS]) {
    data[DAM_COLLECTION_FIELD_KEY.ASSETSIDS].forEach((asset) => {
      formData.append([DAM_COLLECTION_API_RESPONSE_FIELD_KEY.ASSETSIDS] + "[]", asset);
    });
  }
  return formData;
};
var AssetsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      if (entities?._embedded?.item) {
        this.items = entities._embedded.item.map((element) => {
          return new AssetsItemModel(element);
        });
        this.items.pagination = this.getPagination();
      } else {
        this.items = entities.map((element) => {
          return new AssetsItemModel(element);
        });
      }
    }
  }
};
var AssetsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.collection_id = null;
    this.name = null;
    this.alias = null;
    this.uuid = null;
    this.file_extention = null;
    this.file_size = null;
    this.file_mine_type = null;
    this.type_id = null;
    this.type = null;
    this.download_url = null;
    this.owner = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [DAM_ASSETS_FIELD_KEY.ID]: this.id,
        [DAM_ASSETS_FIELD_KEY.NAME]: this.name,
        [DAM_ASSETS_FIELD_KEY.COLLECTION_ID]: this.collection_id,
        [DAM_ASSETS_FIELD_KEY.ALIAS]: this.alias,
        [DAM_ASSETS_FIELD_KEY.UUID]: this.uuid,
        [DAM_ASSETS_FIELD_KEY.FILE_EXTENTION]: this.file_extention,
        [DAM_ASSETS_FIELD_KEY.FILE_MIME_TYPE]: this.file_mine_type,
        [DAM_ASSETS_FIELD_KEY.FILE_SIZE]: this.file_size,
        [DAM_ASSETS_FIELD_KEY.TYPE_ID]: this.type_id,
        [DAM_ASSETS_FIELD_KEY.TYPE]: this.type,
        [DAM_ASSETS_FIELD_KEY.DOWNLOAD_URL]: this.download_url,
        [DAM_ASSETS_FIELD_KEY.OWNER]: this.owner
      };
    };
    if (entity) {
      this.id = entity[DAM_ASSETS_FIELD_KEY.ID] ?? "";
      this.name = entity[DAM_ASSETS_FIELD_KEY.NAME] ?? "";
      this.collection_id = entity[DAM_ASSETS_FIELD_KEY.COLLECTION_ID] ?? "";
      this.alias = entity[DAM_ASSETS_FIELD_KEY.ALIAS] ?? "";
      this.uuid = entity[DAM_ASSETS_FIELD_KEY.UUID] ?? "";
      this.file_extention = entity[DAM_ASSETS_FIELD_KEY.FILE_EXTENTION] ?? "";
      this.file_mine_type = entity[DAM_ASSETS_FIELD_KEY.FILE_MIME_TYPE] ?? "";
      this.file_size = entity[DAM_ASSETS_FIELD_KEY.FILE_SIZE] ? (+entity[DAM_ASSETS_FIELD_KEY.FILE_SIZE] / 1024).toFixed(2) : "";
      this.type_id = entity[DAM_ASSETS_FIELD_KEY.TYPE_ID] ?? "";
      this.type = entity[DAM_ASSETS_FIELD_KEY.TYPE] ?? "";
      this.download_url = entity[DAM_ASSETS_FIELD_KEY.DOWNLOAD_URL] ?? "";
      this.owner = entity[DAM_ASSETS_FIELD_KEY.OWNER] ?? "";
    }
  }
};
AssetsItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [DAM_ASSETS_FIELD_KEY.COLLECTION_ID, DAM_ASSETS_FIELD_KEY.FILE];
  Object.keys(DAM_ASSETS_API_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(DAM_ASSETS_FIELD_KEY[index]) && data[DAM_ASSETS_FIELD_KEY[index]]) {
      formData.append(DAM_ASSETS_API_FIELD_KEY[index], data[DAM_ASSETS_FIELD_KEY[index]]);
    }
  });
  formData.append(
    DAM_ASSETS_API_FIELD_KEY.COLLECTION_ID,
    data[DAM_ASSETS_FIELD_KEY.COLLECTION_ID] ?? 0
  );
  if (data[DAM_ASSETS_FIELD_KEY.FILE]) {
    data[DAM_ASSETS_FIELD_KEY.FILE].forEach((file) => {
      formData.append([DAM_ASSETS_API_FIELD_KEY.FILE] + "[]", file);
    });
  }
  return formData;
};
AssetsItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = new FormData();
  const excluded = [DAM_ASSETS_FIELD_KEY.COLLECTION_ID, DAM_ASSETS_FIELD_KEY.FILE];
  Object.keys(DAM_ASSETS_API_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(DAM_ASSETS_FIELD_KEY[index]) && data[DAM_ASSETS_FIELD_KEY[index]]) {
      formData.append(DAM_ASSETS_API_FIELD_KEY[index], data[DAM_ASSETS_FIELD_KEY[index]]);
    }
  });
  formData.append(
    DAM_ASSETS_API_FIELD_KEY.COLLECTION_ID,
    data[DAM_ASSETS_FIELD_KEY.COLLECTION_ID] ?? 0
  );
  if (data[DAM_ASSETS_FIELD_KEY.FILE]) {
    formData.append([DAM_ASSETS_API_FIELD_KEY.FILE] + "[]", data[DAM_ASSETS_FIELD_KEY.FILE]);
  }
  return formData;
};
AssetsItemModel.__transformItemToApiOfDelete = (data) => {
  const formData = queryString.stringify(
    { [DAM_ASSETS_API_FIELD_KEY.IDS]: data },
    { arrayFormat: "bracket" }
  );
  return formData;
};
var SubscriptionModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new SubsctiptionItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var SubsctiptionItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.product = null;
    this.product_type = null;
    this.product_option = null;
    this.product_storage_usage = null;
    this.license = null;
    this.package = null;
    this.package_name = null;
    this.package_storage_limit = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [DAM_SUBSCIPTION_FIELD_KEY.ID]: this.id,
        [DAM_SUBSCIPTION_FIELD_KEY.PRODUCT]: this.product,
        [DAM_SUBSCIPTION_FIELD_KEY.PACKAGE]: this.package,
        [DAM_SUBSCIPTION_FIELD_KEY.LICENSE]: this.license
      };
    };
    if (entity) {
      this.id = entity[DAM_SUBSCIPTION_API_FIELD_KEY.ID] ?? "";
      if (entity[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT]) {
        this.product = {
          [DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_TYPE]: entity[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT][0][DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_TYPE] ?? "",
          [DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_OPTION]: entity[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT][0][DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_OPTION] ?? {},
          [DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_STORAGE_USAGE]: Math.floor(
            +entity[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT][0][DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_STORAGE_USAGE] / 1e6
          ) ?? 0
        };
      }
      this.license = entity[DAM_SUBSCIPTION_FIELD_KEY.LICENSE] ?? "";
      if (entity[DAM_SUBSCIPTION_FIELD_KEY.PACKAGE]) {
        this.package = {
          [DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_NAME]: entity[DAM_SUBSCIPTION_FIELD_KEY.PACKAGE][DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_NAME] ?? "",
          [DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_STORAGE_LIMIT]: Math.floor(
            entity[DAM_SUBSCIPTION_FIELD_KEY.PACKAGE][DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_STORAGE_LIMIT].replace(",", "")
          ) ?? ""
        };
      }
    }
  }
};

export {
  ColectionModel,
  CollectionItemModel,
  AssetsModel,
  AssetsItemModel,
  SubscriptionModel,
  SubsctiptionItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

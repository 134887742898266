import {
  CMS_CATE_DETAIL_FIELD_KEY
} from "./chunk-GXKHLBEY.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Cms/Fields/CMSFieldsModel.ts
var FieldsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new FieldsItemModel(element);
      });
    }
  }
};
var FieldsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.check = false;
    this.engagement = "";
    this.languages = "";
    this.name = "";
    this.status = "";
    this.type = "";
    this.visits = "";
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CMS_CATE_DETAIL_FIELD_KEY.ID]: this.id,
        [CMS_CATE_DETAIL_FIELD_KEY.CHECK]: this.check,
        [CMS_CATE_DETAIL_FIELD_KEY.ENGAGEMENT]: this.engagement,
        [CMS_CATE_DETAIL_FIELD_KEY.LANGUAGES]: this.languages,
        [CMS_CATE_DETAIL_FIELD_KEY.NAME]: this.name,
        [CMS_CATE_DETAIL_FIELD_KEY.STATUS]: this.status,
        [CMS_CATE_DETAIL_FIELD_KEY.TYPE]: this.type,
        [CMS_CATE_DETAIL_FIELD_KEY.VISITS]: this.visits
      };
    };
    if (entity) {
      this.id = entity[CMS_CATE_DETAIL_FIELD_KEY.ID] ?? "";
      this.check = entity[CMS_CATE_DETAIL_FIELD_KEY.CHECK] ?? "";
      this.engagement = entity[CMS_CATE_DETAIL_FIELD_KEY.ENGAGEMENT] ?? "";
      this.languages = entity[CMS_CATE_DETAIL_FIELD_KEY.LANGUAGES] ?? "";
      this.name = entity[CMS_CATE_DETAIL_FIELD_KEY.NAME] ?? "";
      this.status = entity[CMS_CATE_DETAIL_FIELD_KEY.STATUS] ?? "";
      this.type = entity[CMS_CATE_DETAIL_FIELD_KEY.TYPE] ?? "";
      this.visits = entity[CMS_CATE_DETAIL_FIELD_KEY.VISITS] ?? "";
    }
  }
};
FieldsItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  formData.append("id", data[CMS_CATE_DETAIL_FIELD_KEY.ID] ?? 0);
  return formData;
};

export {
  FieldsModel,
  FieldsItemModel
};

import {
  CONTENTX_CATE_DETAIL_FIELD_KEY
} from "./chunk-4WQQU2XI.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/ContentX/Categories/ContentXCategoriesModel.ts
var CategoryModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new CategoriesItemModel(element);
      });
    }
  }
};
var CategoriesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.check = false;
    this.engagement = "";
    this.languages = "";
    this.title = "";
    this.status = "";
    this.type = "";
    this.visits = "";
    this.description = "";
    this.featured_image = "";
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CONTENTX_CATE_DETAIL_FIELD_KEY.ID]: this.id,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.CHECK]: this.check,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.ENGAGEMENT]: this.engagement,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.LANGUAGES]: this.languages,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.TITLE]: this.title,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.STATUS]: this.status,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.TYPE]: this.type,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.VISITS]: this.visits,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.DESCRIPTION]: this.description,
        [CONTENTX_CATE_DETAIL_FIELD_KEY.FEATURED_IMAGE]: this.featured_image
      };
    };
    if (entity) {
      this.id = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.ID] ?? "";
      this.check = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.CHECK] ?? "";
      this.engagement = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.ENGAGEMENT] ?? "";
      this.languages = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.LANGUAGES] ?? "";
      this.title = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.TITLE] ?? "";
      this.status = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.STATUS] ?? "";
      this.type = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.TYPE] ?? "";
      this.visits = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.VISITS] ?? "";
      this.description = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.DESCRIPTION] ?? "";
      this.featured_image = entity[CONTENTX_CATE_DETAIL_FIELD_KEY.FEATURED_IMAGE]?.url ?? "";
    }
  }
};
CategoriesItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  formData.append(
    CONTENTX_CATE_DETAIL_FIELD_KEY.ID,
    data[CONTENTX_CATE_DETAIL_FIELD_KEY.ID] ?? 0
  );
  return formData;
};

export {
  CategoryModel,
  CategoriesItemModel
};

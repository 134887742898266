import {
  CMSFieldsRoute_default
} from "./chunk-PUWGX56P.js";
import {
  FieldsItemModel
} from "./chunk-R462AICZ.js";

// src/Cms/Fields/CMSFields.ts
import axios from "axios";
var AesirxCmsFieldsApiService = class {
  constructor() {
    this.route = null;
    this.getList = async () => {
      try {
        const data = await this.route.getList();
        let results = null;
        if (data) {
          results = new FieldsItemModel(data);
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.create = async (data) => {
      try {
        const result = await this.route.create(data);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.update = async (data) => {
      try {
        const result = await this.route.update(data);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDetail = async (id = 0) => {
      try {
        const data = await this.route.getDetail(id);
        let results = null;
        if (data) {
          results = new FieldsItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new CMSFieldsRoute_default();
  }
};

export {
  AesirxCmsFieldsApiService
};

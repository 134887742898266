import {
  CAMPAIGN_API_RESPONSE_FIELD_KEY,
  CAMPAIGN_FIELD_KEY
} from "./chunk-Z2J6BGZO.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Campaign/CampaignModel.ts
var CampaignModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new CampaignItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var CampaignFilterModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.getPagination = () => {
      const pagination = this.pureEntities.result.pagination;
      return {
        page: pagination.page ? parseInt(pagination.page) : 1,
        pageLimit: pagination.pageLimit ? parseInt(pagination.pageLimit) : 0,
        limitstart: pagination.limitStart ? parseInt(pagination.limitStart) : 0,
        totalItems: pagination.totalItems ? parseInt(pagination.totalItems) : 0,
        totalPages: pagination.totalPage ? parseInt(pagination.totalPage) : 0
      };
    };
    if (entities) {
      this.unTransformedItems = entities.result.data;
      this.items = entities.result.data.map((element) => {
        return new CampaignItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var CampaignItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.name = "";
    this.startDate = "0000-00-00 00:00:00";
    this.endDate = "0000-00-01 00:00:00";
    this.status = 0;
    this.project = 0;
    this.percentComplete = 0;
    this.noToDoPosts = 0;
    this.noSchedulePosts = 0;
    this.noPublishedPosts = 0;
    this.data = "";
    this.published = "";
    this.toObject = () => {
      return {
        [CAMPAIGN_FIELD_KEY.ID]: this.id,
        [CAMPAIGN_FIELD_KEY.NAME]: this.name,
        [CAMPAIGN_FIELD_KEY.STATUS]: this.status,
        [CAMPAIGN_FIELD_KEY.PUBLISHED]: this.published,
        [CAMPAIGN_FIELD_KEY.PROJECT]: this.project,
        [CAMPAIGN_FIELD_KEY.START_DATE]: this.startDate,
        [CAMPAIGN_FIELD_KEY.END_DATE]: this.endDate,
        [CAMPAIGN_FIELD_KEY.PERCENT_COMPLETE]: this.percentComplete,
        [CAMPAIGN_FIELD_KEY.NO_TO_DO_POSTS]: this.noToDoPosts,
        [CAMPAIGN_FIELD_KEY.NO_SCHEDULED_POSTS]: this.noSchedulePosts,
        [CAMPAIGN_FIELD_KEY.NO_PUBLISHED_POSTS]: this.noPublishedPosts,
        [CAMPAIGN_FIELD_KEY.DATA]: this.data
      };
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CAMPAIGN_FIELD_KEY.ID]: this.id,
        [CAMPAIGN_FIELD_KEY.NAME]: this.name,
        [CAMPAIGN_FIELD_KEY.STATUS]: this.status,
        [CAMPAIGN_FIELD_KEY.PUBLISHED]: this.published,
        [CAMPAIGN_FIELD_KEY.PROJECT]: this.project,
        [CAMPAIGN_FIELD_KEY.START_DATE]: this.startDate,
        [CAMPAIGN_FIELD_KEY.END_DATE]: this.endDate,
        [CAMPAIGN_FIELD_KEY.PERCENT_COMPLETE]: this.percentComplete,
        [CAMPAIGN_FIELD_KEY.NO_TO_DO_POSTS]: this.noToDoPosts,
        [CAMPAIGN_FIELD_KEY.NO_SCHEDULED_POSTS]: this.noSchedulePosts,
        [CAMPAIGN_FIELD_KEY.NO_PUBLISHED_POSTS]: this.noPublishedPosts,
        [CAMPAIGN_FIELD_KEY.DATA]: this.data
      };
    };
    if (entity) {
      this.name = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.NAME] ?? "";
      this.startDate = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.START_DATE] ?? "0000-00-00 00:00:00";
      this.endDate = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.END_DATE] ?? "0000-00-00 00:00:00";
      this.status = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.STATUS] ?? "";
      this.published = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.PUBLISHED] ?? "";
      this.project = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.PROJECT] ?? "";
      this.percentComplete = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.PERCENT_COMPLETE] ?? "";
      this.noToDoPosts = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_TO_DO_POSTS] ?? "";
      this.noSchedulePosts = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_SCHEDULED_POSTS] ?? "";
      this.noPublishedPosts = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_PUBLISHED_POSTS] ?? "";
      this.data = entity[CAMPAIGN_API_RESPONSE_FIELD_KEY.DATA] ?? "";
    }
  }
};
CampaignItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NAME]: data[CAMPAIGN_FIELD_KEY.NAME] ?? "",
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.PROJECT]: data[CAMPAIGN_FIELD_KEY.PROJECT ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.START_DATE]: data[CAMPAIGN_FIELD_KEY.START_DATE ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.END_DATE]: data[CAMPAIGN_FIELD_KEY.END_DATE ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.PERCENT_COMPLETE]: data[CAMPAIGN_FIELD_KEY.PERCENT_COMPLETE ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_TO_DO_POSTS]: data[CAMPAIGN_FIELD_KEY.NO_TO_DO_POSTS ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_SCHEDULED_POSTS]: data[CAMPAIGN_FIELD_KEY.NO_SCHEDULED_POSTS ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_PUBLISHED_POSTS]: data[CAMPAIGN_FIELD_KEY.NO_PUBLISHED_POSTS ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.DATA]: data[CAMPAIGN_FIELD_KEY.DATA] ?? ""
  };
};
CampaignItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.ID]: data[CAMPAIGN_FIELD_KEY.ID] ?? "",
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NAME]: data[CAMPAIGN_FIELD_KEY.NAME] ?? "",
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.PROJECT]: data[CAMPAIGN_FIELD_KEY.PROJECT ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.START_DATE]: data[CAMPAIGN_FIELD_KEY.START_DATE ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.END_DATE]: data[CAMPAIGN_FIELD_KEY.END_DATE ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.PERCENT_COMPLETE]: data[CAMPAIGN_FIELD_KEY.PERCENT_COMPLETE ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_TO_DO_POSTS]: data[CAMPAIGN_FIELD_KEY.NO_TO_DO_POSTS ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_SCHEDULED_POSTS]: data[CAMPAIGN_FIELD_KEY.NO_SCHEDULED_POSTS ?? ""],
    [CAMPAIGN_API_RESPONSE_FIELD_KEY.NO_PUBLISHED_POSTS]: data[CAMPAIGN_FIELD_KEY.NO_PUBLISHED_POSTS ?? ""],
    [CAMPAIGN_FIELD_KEY.DATA]: data[CAMPAIGN_FIELD_KEY.DATA] ?? ""
  };
};

export {
  CampaignModel,
  CampaignFilterModel,
  CampaignItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

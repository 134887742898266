import {
  AesirXSelectExtended
} from "./chunk-HMAYFD5S.js";
import {
  __publicField
} from "./chunk-6FER3ETN.js";

// src/components/Form/FormSelectDropdown/index.tsx
import React, { Component } from "react";
import { components } from "react-select";
var FormSelectDropdown = class extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "field");
    this.field = this.props.field;
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      AesirXSelectExtended,
      {
        defaultValue: this.field.value,
        onChange: this.field.changed,
        options: this.field.option,
        isBorder: true,
        plColor: "rgba(8, 18, 64, 0.8)",
        isMulti: this.field.isMulti ?? false,
        components: {
          Option: this.field.optionComponent ? this.field.optionComponent : components.Option,
          Placeholder: this.field.placeholderComponent ? this.field.placeholderComponent : components.Placeholder
        },
        async: this.field.async ?? false,
        loadOptions: this.field.loadOptions ?? null,
        cacheOptions: true,
        placeholder: this.field.placeholder
      }
    );
  }
};

export {
  FormSelectDropdown
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
